import axios from 'axios';
//const API_URL = "https://book.ardi.one/api/";
//const API_URL = "https://cmd.ardi.one/api/";
//const API_URL = "http://16.170.23.185:8000/api/";
const API_URL = "https://newtest.ardi.one/api/";
const BASE_URL = "https://newbook.ardi.one/"
axios.interceptors.request.use(
  async (config) => {
    const userJwtToken = localStorage.getItem("token");
    if (userJwtToken) {
      config.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
      
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use((res) => res, (error) => {
  console.log(error)
  if(error){
    const originalConfig = error.config;
    if (originalConfig.url !== "/auth/signin" && error.response) {
      // Access Token was expired
      if (error.response.status === 403 && error.response.data.message === 'TokenExpiredError: jwt expired') {
        try {
          localStorage.clear()
          window.location = "/";
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
      if (error.response.status === 401 && error.response.data.message === 'token missing') {
        try {
          localStorage.clear()
          window.location = "/";
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error);
  }
  return Promise.resolve();
});

const axiosExport = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  Api:API_URL,
  Base: BASE_URL
}

export default axiosExport;

