import logo from './logo.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import { MainRoutes } from './router';
import { Auth0Provider } from '@auth0/auth0-react';

function App() {
  let themeName = 'theme-default';
  if (window.location.href.indexOf("cityquest") > -1) {
    themeName = 'theme-green';
  } else if (window.location.href.indexOf("homequest") > -1) {
    themeName = 'theme-blue'
  } else if (window.location.href.indexOf("officequest") > -1) {
    themeName = 'theme-yellow'
  } else if (window.location.href.indexOf("halloween") > -1) {
    themeName = 'theme-purple'
  } else if (window.location.href.indexOf("schoolquest") > -1) {
    themeName = 'theme-default'
  }
  return (
    <Auth0Provider
    domain="cityquest-se-dev.eu.auth0.com"
    clientId="3RJ226qn4n6JRGGFhzEhlpjao6EXPL8T"
    audience='https://cityquest-se-dev.eu.auth0.com/api/v2/'
    authorizationParams={{
      redirect_uri: 'http://localhost:3000/login'
    }}
    onRedirectCallback='http://localhost:3000/login'
  >
    <div className={`App ${themeName}`} >
      <MainRoutes/>
    </div>
    </Auth0Provider>
  );
}

export default App;
